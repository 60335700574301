<template>
    <l-map id="map" @focus="handleFocus"  @focusout="handleFocusOut" :options="{scrollWheelZoom:scrollWheelZoom}" :zoom="zoom" :center="center" :zoomAnimation="zoomAnimation" :fadeAnimation="fadeAnimation" :markerZoomAnimation="markerZoomAnimation">
      <l-tile-layer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :attributionControl="false"
      :leafletAttributionControl="false"
      layer-type="base"
    />
    <l-marker v-for="location in locations" :lat-lng="location.latlng">
        <l-icon
          :title="location.name"
          icon-url="/img/Pointer.svg"
        />
        <l-tooltip
          :content="location.name"
        />
    </l-marker>
    <l-marker :lat-lng="[51.907760, 4.476860]">
        <l-icon
          title="Rotterdam"
          icon-url="/img/Pointer-hq.svg"
        />
        <l-tooltip
          content="<div style='font-size:10px;padding-left:10px;padding-right:10px;line-height:5px;'>headquarters</div><br><div style='line-height:5px;padding-left:10px;padding-right:10px; margin-top:-10px !important;'>Rotterdam</div>"
        />
    </l-marker>
  </l-map>
  </template>
  <script>
  import "leaflet/dist/leaflet.css";
  import {LMap, LTileLayer, LControlLayers, LPolyline, LPolygon, LCircle, LCircleMarker, LMarker, LIcon, LGeoJson, LPopup, LTooltip} from 'vue2-leaflet';
  
  export default {
    components: {
      LMap,
      LTileLayer,
      LControlLayers,
      LPolyline,
      LPolygon,
      LCircle,
      LMarker,
      LGeoJson,
      LPopup,
      LTooltip,
      LCircleMarker,
      LIcon
    },
    mounted: function () {
          this.zoomRatio = 2;
    },
    methods: {
      handleFocus() {

        },
        handleFocusOut() {

        }
    },
    data () {
      return {
        tileProviders: [
        {
          name: 'OSM',
          visible: true,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', //https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png https://tile.openstreetmap.org/{z}/{x}/{y}.png
        }
      ],
        locations: [
          {
            name: '✈ Amsterdam (AMS)',
            latlng: [52.308333, 4.760833],
          },
          {
            name: '✈ Copenhagen (CPH)',
            latlng: [55.619167, 12.648056],
          },
          {
            name: '✈ Brussels (BRU)',
            latlng: [50.901389, 4.484444],
          },
          {
            name: '✈ Liege (LGG)',
            latlng: [50.637222, 5.443056],
          },
          {
            name: '✈ Düsseldorf (DUS)',
            latlng: [51.289444, 6.766667],
          },
          {
            name: '✈ Hannover (HAJ)',
            latlng: [52.460214, 9.683522],
          },
          {
            name: '✈ Hamburg (HAM)',
            latlng: [53.630278, 9.991111],
          },
          {
            name: '✈ Frankfurt am Main (FRA)',
            latlng: [50.026389, 8.543056],
          },
          {
            name: '✈ Paris (CDG)',
            latlng: [49.013611, 2.557778],
          },
          {
            name: '✈ London (LHR)',
            latlng: [51.4775, -0.461389],
          },
          {
            name: '✈ Manchester (MAN)',
            latlng: [53.353889, -2.275],
          },
          {
            name: '✈ Basel (BSL)',
            latlng: [47.589444, 7.53],
          },
          {
            name: '✈ Munich (MUC)',
            latlng: [48.353889, 11.786111],
          },
          {
            name: '✈ Vienna (VIE)',
            latlng: [48.110278, 16.569722],
          },
          {
            name: '✈ Madrid (MAD)',
            latlng: [40.472222, -3.560833],
          },
          {
            name: '✈ Barcelona (BCN)',
            latlng: [41.296944, 2.078333],
          },
          {
            name: '✈ Larnaca (LCA)',
            latlng: [34.878889, 33.630278],
          },
          {
            name: '✈ Johannesburg (JNB)',
            latlng: [-26.133333, 28.25],
          },
          {
            name: '✈ Cape Town (CPT)',
            latlng: [-33.969444, 18.597222],
          },
          {
            name: '✈ Milan (MXP)',
            latlng: [45.63, 8.723056],
          },
          {
            name: '✈ Cork (ORK)',
            latlng: [51.841389, -8.491111],
          },
          {
            name: '✈ Shannon (SNN)',
            latlng: [52.701944, -8.924722],
          },
          {
            name: '✈ Dublin (DUB)',
            latlng: [53.421389, -6.27],
          },
          {
            name: '✈ Chicago (ORD)',
            latlng: [41.979444, -87.904444],
          },
          {
            name: '✈ Helsinki (HEL)',
            latlng: [60.317222, 24.963333],
          },
          {
            name: '✈ Atlanta (ATL)',
            latlng: [33.636667, -84.428056],
          },
          {
            name: '✈ Boston (BOS)',
            latlng: [42.364167, -71.005],
          },
          {
            name: '✈ Miami (MIA)',
            latlng: [25.793333, -80.290556],
          },
          {
            name: '✈ Los Angeles (LAX)',
            latlng: [33.9425, -118.408056],
          },
          {
            name: '✈ Seattle (SEA)',
            latlng: [47.448889, -122.309444],
          },
          {
            name: '✈ San Francisco (SFO)',
            latlng: [37.618889, -122.375],
          }
        ],
        zoom: 2,
        zoomAnimation: true,
        fadeAnimation: false,
        markerZoomAnimation: false,
        center: [28,-35],
        zoomRatio: zoomRatio,
        scrollWheelZoom: false
      };
    },
  }
  </script>

<style>
#map {
  background-color: #fff;
  height:400px;
  width:100%;
  border-radius: 5px;
}

.frontend-body #map {
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.3);
  margin-bottom: 40px;
  height: 700px;
}

@media all and (max-width: 750px) {
  .frontend-body #map {
    height: 400px;
  }
};

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.6;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-tooltip {
    background-color: #ffd832;
    color: #4b4b4b;
    font-weight: 500;
    border: 0px;
    box-shadow: none;
    border-radius: 2.5rem;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    margin-top:-50%;
    font-family: "Telegraf", sans-serif;
}
.leaflet-tooltip-top:before, 
.leaflet-tooltip-bottom:before, 
.leaflet-tooltip-left:before, 
.leaflet-tooltip-right:before {
    border: none !important;
}

.leaflet-bar a {
  background-color: #e4e4e4;
  color: #7e7e7e;
}
</style>